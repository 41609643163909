export const topDealUsers = [
    {
        id: 1,
        username: "Harry Potter",
        img: "assets/Avatar.png",
        amount: "2800",
        email: `chimaobi01@gmail.com`
    },
    {
        id: 2,
        username: "Campbell Oshea",
        img: "assets/Avatar.png",
        amount: "2800",
        email: `chimaobi01@gmail.com`
    },
    {
        id: 3,
        username: "Wyann Giggs",
        img: "assets/Avatar.png",
        amount: "2800",
        email: `chimaobi01@gmail.com`
    },
    {
        id: 4,
        username: "Chimamanda Adichie",
        img: "assets/Avatar.png",
        amount: "2800",
        email: `chimaobi01@gmail.com`
    },
    {
        id: 5,
        username: "Tom Jerry",
        img: "assets/Avatar.png",
        amount: "2800",
        email: `chimaobi01@gmail.com`
    },
]